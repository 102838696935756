import React from "react";
import { Helmet } from "react-helmet";
import { useSiteMetadata } from "web-common/src/hooks/useSiteMetadata";
import "./styles.scss";

const OneTrust = () => {
  const { oneTrustDataDomainScriptId } = useSiteMetadata();
  const autoBlockScript = `https://cdn.cookielaw.org/consent/${oneTrustDataDomainScriptId}/OtAutoBlock.js`;

  return (
    <>
      <Helmet>
        <script type="text/javascript" src={autoBlockScript} />
        <script
          src="https://cdn.cookielaw.org/scripttemplates/otSDKStub.js"
          data-document-language="true"
          type="text/javascript"
          charSet="UTF-8"
          data-domain-script={oneTrustDataDomainScriptId}
        />
        <script>{`
          function OptanonWrapper(){}
        `}</script>
      </Helmet>
    </>
  );
};

export default OneTrust;
